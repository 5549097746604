import React from "react"


const SlideshowControllerSmall = ({ xPos, yPos, handleNextClick, handleBackClick, state, totalSlides }) => {

    return (
        <div className="slideshow-controller-small" style={{left: xPos, top: yPos}}>
            <div className="tape" />
            <button className="arrow arrow--back" onClick={handleBackClick} />
            <button className="arrow arrow--next" onClick={handleNextClick} />
        </div>

    )
}

export default SlideshowControllerSmall