/** Book **/

import React from "react";
import { gsap } from "gsap"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import Sound from 'react-sound';
import Carousel from "../../components/media/carousel";
import Icon from "../../components/intro/icon";
import SlideshowControllerSmall from "../../components/media/slideshow-controller-small";
import SlideshowController from "../../components/media/slideshow-controller";


let globalState, data, preloadData, slideshow1Data, slideshow2Data, slideshow3Data, slideshow4Data, slideshow5Data, slideshow6Data;

const trucksWrapperHeight = 405;
const trucksHeight = 9450; //1406;
let trucks, t2, t1, ty = 0, trucksWrapper, scrollFactor, scrollStrength = 0, currentScroll = 0, isScrolling = false;



class IntroPage3 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[3];
    preloadData = globalState.assets[4];
    
    //refs
    slideshow1Data = [
      {
        file: globalState.cdnUrl + data.p2slide1,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide2,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide3,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide4,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide5,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide6,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide7,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide8,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide9,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide10,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide11,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide12,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide13,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide14,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide15,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.p2slide16,
        fileType: 'image'
      }
    ]

    slideshow2Data = [
      {
        file: globalState.cdnUrl + data.polaroid1,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid2,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid3,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid4,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid5,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid6,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid7,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid8,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid9,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid10,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid11,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid12,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid13,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid14,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid15,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid16,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid17,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid18,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid19,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid20,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid21,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid22,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid23,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid24,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid25,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid26,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid27,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid28,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid29,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid30,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.polaroid31,
        fileType: 'image'
      }
    ]

    slideshow3Data = [
      {
        file: globalState.cdnUrl + data.wheelie1,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.wheelie2,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.wheelie3,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.wheelie4,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.wheelie5,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.wheelie6,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.wheelie7,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.wheelie8,
        fileType: 'image'
      }
    ]

    slideshow4Data = [
      {
        file: globalState.cdnUrl + data.hair1,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair2,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair3,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair4,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair5,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair6,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair7,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair8,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair9,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair10,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair11,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair12,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair13,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair14,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair15,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair16,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair17,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair18,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair19,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair20,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.hair21,
        fileType: 'image'
      }
    ]

    slideshow5Data = [
      {
        file: globalState.cdnUrl + data.panel1,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel2,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel3,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel4,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel5,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel6,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel7,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel8,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel9,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel10,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel11,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel12,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel13,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel14,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel15,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel16,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel17,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.panel18,
        fileType: 'image'
      }
    ]

    slideshow6Data = [
      {
        file: globalState.cdnUrl + data.tower1,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.tower2,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.tower3,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.tower4,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.tower5,
        fileType: 'image'
      }
    ]

  }

  state = {
    textVisible: false,
    currentPage: 0,
    totalPages: 3,
    playPageFlipSound: false,
    currentSlide: 1,
    carousel1CurrentSlide: 1,
    carousel2CurrentSlide: 1,
    carousel3CurrentSlide: 1,
    carousel4CurrentSlide: 1,
    carousel5CurrentSlide: 1,
    carousel6CurrentSlide: 1,
    autoAdvance: false,
  }

  componentDidMount = () => {
    this.showPrevNext();

    globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);  //book basetrack (loop)
    globalState.baseAudioRef.updateTrack(2, false);  //intro basetrack (remove)
    
    //setTimeout(()=>{
    //}, 500);


    if(globalState.transitionDirection === "prev") {
      console.log('START BOOK ON LAST PAGE ------');
      this.setState({ currentPage: this.state.totalPages });

      this.refs.flipper1Ref.classList.toggle('flip');
      this.refs.flipper2Ref.classList.toggle('flip');
      this.refs.flipper3Ref.classList.toggle('flip');
      
      this.refs.flipper1Ref.style.zIndex = 1;
      this.refs.flipper2Ref.style.zIndex = 2;
      this.refs.flipper3Ref.style.zIndex = 3;
      this.updateHijacking(this.state.totalPages);
    }
    

    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.skaterVideoPoster,
      globalState.cdnUrl + preloadData.text1,
      globalState.cdnUrl + preloadData.text2,
      globalState.cdnUrl + preloadData.caption], 
      this.preloadCallback);
  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }

  componentDidUpdate() {
    console.log("componentDidUpdate currentPage: " + this.state.currentPage);

    if(this.state.currentPage === 1){
      this.reqAnimFrame = window.requestAnimationFrame(this.moveTrucks);
    }

  }

  componentWillUnmount() {
    //remove resize listener

    // this.killAnimations();

    //remove page sounds
    globalState.baseAudioRef.updateTrack(2, false);
    //remove icon drop sound
    globalState.baseAudioRef.updateTrack(3, false);
  }

  //Slideshow controls
  

  //update state and slide for current slide of current carousel
  updateSlideState = (carouselNum, currentSlideNum) => {
    let carouselRef = this.refs['carousel'+carouselNum+'Ref'];
    this.setState({ ['carousel'+carouselNum+'CurrentSlide']:currentSlideNum });
    carouselRef.updateCurrentSlide(currentSlideNum, true);
  }

  handleNextClick1 = () => {
    let nextSlide = this.state.carousel1CurrentSlide + 1;
    if (nextSlide > slideshow1Data.length) {
      nextSlide = 1;
    }
    this.updateSlideState(1, nextSlide);
    
  }
  
  handleBackClick1 = () => {
    let nextSlide = this.state.carousel1CurrentSlide - 1;
    if (nextSlide < 1) {
      nextSlide = slideshow1Data.length;
    }
    this.updateSlideState(1, nextSlide);
  }

  handleNextClick2 = () => {
    let nextSlide = this.state.carousel2CurrentSlide + 1;
    if (nextSlide > slideshow2Data.length) {
      nextSlide = 1;
    }
    this.updateSlideState(2, nextSlide);
  }
  
  handleBackClick2 = () => {
    let nextSlide = this.state.carousel2CurrentSlide - 1;
    if (nextSlide < 1) {
      nextSlide = slideshow2Data.length;
    }
    this.updateSlideState(2, nextSlide);
  }

  handleNextClick3 = () => {
    let nextSlide = this.state.carousel3CurrentSlide + 1;
    if (nextSlide > slideshow3Data.length) {
      nextSlide = 1;
    }
    this.updateSlideState(3, nextSlide);
  }
  
  handleBackClick3 = () => {
    let nextSlide = this.state.carousel3CurrentSlide - 1;
    if (nextSlide < 1) {
      nextSlide = slideshow3Data.length;
    }
    this.updateSlideState(3, nextSlide);
  }

  handleNextClick4 = () => {
    let nextSlide = this.state.carousel4CurrentSlide + 1;
    if (nextSlide > slideshow4Data.length) {
      nextSlide = 1;
    }
    this.updateSlideState(4, nextSlide);
    this.setState({ currentSlide: nextSlide });
  }
  
  handleBackClick4 = () => {
    let nextSlide = this.state.carousel4CurrentSlide - 1;
    if (nextSlide < 1) {
      nextSlide = slideshow4Data.length;
    }
    this.updateSlideState(4, nextSlide);
    this.setState({ currentSlide: nextSlide });
  }

  handleNextClick5 = () => {
    let nextSlide = this.state.carousel5CurrentSlide + 1;
    if (nextSlide > slideshow5Data.length) {
      nextSlide = 1;
    }
    this.updateSlideState(5, nextSlide);
  }
  
  handleBackClick5 = () => {
    let nextSlide = this.state.carousel5CurrentSlide - 1;
    if (nextSlide < 1) {
      nextSlide = slideshow5Data.length;
    }
    this.updateSlideState(5, nextSlide);
  }

  handleNextClick6 = () => {
    let nextSlide = this.state.carousel6CurrentSlide + 1;
    if (nextSlide > slideshow6Data.length) {
      nextSlide = 1;
    }
    this.updateSlideState(6, nextSlide);
  }
  
  handleBackClick6 = () => {
    let nextSlide = this.state.carousel6CurrentSlide - 1;
    if (nextSlide < 1) {
      nextSlide = slideshow6Data.length;
    }
    this.updateSlideState(6, nextSlide);
  }



  flipPageNext = () => {


    let nextPage = this.state.currentPage + 1;
    const flipperRef = this.refs["flipper" + nextPage + "Ref"];

    flipperRef.style.zIndex = nextPage;
    flipperRef.classList.toggle('flip');

    this.setState({ currentPage: nextPage });

    //update hijacking status
    this.updateHijacking(nextPage);

    this.updateSound(nextPage);

    this.showPrevNext();
  }

  flipPagePrev = () => {
    let prevPage = this.state.currentPage;

    const flipperRef = this.refs["flipper" + prevPage + "Ref"];

    console.log("pg: " + prevPage);

    flipperRef.classList.toggle('flip');

    prevPage -= 1;
    this.setState({ currentPage: prevPage });

    this.updateSound(prevPage);

    this.updateHijacking(prevPage);
    setTimeout(() => {
      flipperRef.style.zIndex = "unset";
    }, 300);

    this.showPrevNext();
  }

  showPrevNext = (delay = 2) => {
    gsap.delayedCall(delay, this.refs.prevNextRef.showNextBtn);
    gsap.delayedCall(delay, this.refs.prevNextRef.showPrevBtn);
  }

  updateHijacking = (pageNum) => {
    pageNum === this.state.totalPages ? this.refs.prevNextRef.updateNextHijacking(false) : this.refs.prevNextRef.updateNextHijacking(true);

    pageNum === 0 ? this.refs.prevNextRef.updatePrevHijacking(false) : this.refs.prevNextRef.updatePrevHijacking(true);
  }

  updateSound = (pageNum) => {

    this.setState({ playPageFlipSound: true });
    

    //this.setState({pageFlipSound: pageFlipSound, pageFlipPlayStatus: Sound.status.PLAYING, pageFlipPosition: 0});

    //this.refs.pageFlipRef.

    switch (pageNum) {
      case 0:
        //remove base track
        globalState.baseAudioRef.updateTrack(2, false);

        break;
      case 1:
        globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.page1Sound, 100, true);
        break;
      case 2:
        globalState.baseAudioRef.updateTrack(3, false);
        globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.page2Sound, 100, true);
        break;
      case 3:
        globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.page3Sound, 100, true);
        globalState.baseAudioRef.updateTrack(3, globalState.cdnUrl + data.iconDropSound);
        
        setTimeout(() => {
          this.bounceIcons();
        }, 200); 


        break;

      default:
        break;

    }

  }

  bounceIcons = () => {
          
    for (let i = 1; i <= 10; i++) {
      let icon = this.refs["icon"+i]
      console.log("bounce icons: " + i);
      icon.bounce();
    }
  }

  startTruckScroll = (e) => {
    
    let rect = e.target.getBoundingClientRect();
    let mouseY = e.clientY - rect.top;  //y position within the element.
    scrollStrength = -((mouseY + trucksWrapperHeight/2) - trucksWrapperHeight)/20/5;
    isScrolling = true;

    if(scrollStrength < 0.1 && scrollStrength > -0.1){
      scrollStrength = 0;
    }
  }

  stopTruckScroll = () => {
    isScrolling = false;
  }

  moveTrucks = () => {
    trucks = this.refs.trucksRef;
    trucksWrapper = this.refs.trucksWrapperRef;
    t1 = this.refs.t1Ref;
    t2 = this.refs.t2Ref;

    if(scrollStrength > 0){
      trucksWrapper.classList.add("up");
      trucksWrapper.classList.remove("down");
    } else {
      trucksWrapper.classList.remove("up");
      trucksWrapper.classList.add("down");
    }

    

    //calculate where the two tiles should be positioned
    scrollFactor = -Math.ceil(currentScroll/(trucksHeight));
    t1.style.top = ((scrollFactor) * trucksHeight) + "px";
    t2.style.top = ((scrollFactor+1) * trucksHeight) + "px";
    
    if (isScrolling){
      //console.log("scrollFactor: " + scrollFactor + ", currentScroll: " + currentScroll);
      //console.log("t1.top: " + t1.style.top);
      //console.log("t2.top: " + t2.style.top);
      //console.log("currentScroll: " + currentScroll+ ", scrollStrength: " + scrollStrength);
      ty = currentScroll + scrollStrength;
      trucks.style.top = ty + "px";
      currentScroll = ty;
    }
    
    if(this.state.currentPage === 1){
        //scroll trucks
        this.reqAnimFrame = window.requestAnimationFrame(this.moveTrucks);
    }
  }

  render() {

    return (

      <>

        <link rel="prefetch" href="/intro/4" />
        {preloadData && (
        <link rel="preload" as="video" href={globalState.videoUrl + preloadData.skaterVideo} />
        )}

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/intro/4" nextHijack={true} nextCallback={this.flipPageNext}
          prevLocation="/intro/2" prevHijack={false} prevCallback={this.flipPagePrev} />

        {this.state.playPageFlipSound && !globalState.baseAudioRef.isMuted() ?
          <Sound
            ref="pageFlipRef"
            url={globalState.cdnUrl + data.pageFlipSound}
            playStatus={Sound.status.PLAYING}
            position={0}
            autoLoad={true}
            loop={false}
            onFinishedPlaying={() => {
              this.setState({ playPageFlipSound: false });
            }}
          /> : ""
        }

        {data && (
        <>
        <div className="fullpage-wrapper" style={{ backgroundImage: `url(${globalState.cdnUrl + data.background})`, backgroundSize: "cover", backgroundPosition: "center" }}>
          <SEO title="Pine Point - Intro" />
        </div>

        <img className="book-cover-text" src={globalState.cdnUrl + data.bookCoverText} ref="bookCoverTextRef" alt="THE WEBSITE I FOUND WAS CALLED ‘PINE POINT REVISTED’." />
        <div className="flip-container bookflip-container" ref="flipContainerRef">
          <div className="flipper flipper4" ref="flipper4Ref">
            <div className="front">
              <div className="page page6">

                <Icon ref="icon7" sprites={1} srcFront={globalState.cdnUrl + data.icon7Front} srcBack={globalState.cdnUrl + data.icon7Back} iconFlipSound={globalState.cdnUrl + data.iconFlipSound} globalState={globalState} style={{ position:'absolute', left: '85px', top: '60px', width: '148px', height: '147px' }} />
                
                <Icon ref="icon8" sprites={5} spriteW={148} spriteH={147} spriteTime={10} srcFront={globalState.cdnUrl + data.icon8Front} srcBack={globalState.cdnUrl + data.icon8Back} iconFlipSound={globalState.cdnUrl + data.iconFlipSound} globalState={globalState} style={{ position:'absolute', left: '260px', top: '50px', width: '148px', height: '147px' }} />
                
                <Icon ref="icon9" sprites={1} srcFront={globalState.cdnUrl + data.icon9Front} srcBack={globalState.cdnUrl + data.icon9Back} iconFlipSound={globalState.cdnUrl + data.iconFlipSound} globalState={globalState} style={{ position:'absolute', left: '100px', top: '222px', width: '144px', height: '144px' }} />

                <Icon ref="icon10" sprites={1} srcFront={globalState.cdnUrl + data.icon10Front} srcBack={globalState.cdnUrl + data.icon10Back} iconFlipSound={globalState.cdnUrl + data.iconFlipSound} globalState={globalState} style={{ position:'absolute', left: '275px', top: '222px', width: '148px', height: '147px' }} />


                <img src={globalState.cdnUrl + data.pageRight} className="page-left" alt="" />
                <img src={globalState.cdnUrl + data.text9} className="book-text" style={{ left: "51px", top: "399px" }} alt="Pine Point didn’t have much in the way of phone-book-cover-worthy symbols. The water tower emerges as a constant, lovingly portrayed over years and through seasons. I can’t for the life of me remember what Regina’s icons might have been. Maybe the SaskPower Building. Because it curved." />
                {/* Pg 6 Carousel - Towers */}
                <Carousel ref="carousel6Ref" data={slideshow6Data} globalState={globalState} fullscreen={false} position={['374px', '415px']} size={["122px", "234px"]} state={this.state} />
                <SlideshowControllerSmall xPos="400px" yPos="637px" handleNextClick={this.handleNextClick6} handleBackClick={this.handleBackClick6} state={this.state} totalSlides={slideshow6Data.length} />
              </div>
            </div>
          </div>
          <div className="flipper flipper3" ref="flipper3Ref">
            <div className="front">
              <div className="page page4">
                <img src={globalState.cdnUrl + data.pageRight} className="page-right" alt="" />
                <img src={globalState.cdnUrl + data.text6} className="book-text" style={{ left: "65px", top: "456px" }} alt="The pictures are impossibly friendly. Even the colours and textures seem unselfconscious – wood paneling, perms, velour, deep shag – all enclosed in yellowing photos with rounded corners." />
                {/* Pg 4 Carousel - Hair */}
                <Carousel ref="carousel4Ref" data={slideshow4Data} globalState={globalState} fullscreen={false} position={['174px', '35px']} size={["363px", "365px"]} state={this.state} />
                <SlideshowController xPos="323px" yPos="389px" handleNextClick={this.handleNextClick4} handleBackClick={this.handleBackClick4} state={this.state} totalSlides={slideshow4Data.length} />
                {/* Pg 4 Carousel - Panels */}
                <Carousel ref="carousel5Ref" data={slideshow5Data} globalState={globalState} fullscreen={false} position={['379px', '466px']} size={["166px", "165px"]} state={this.state} />
                <SlideshowControllerSmall xPos="423px" yPos="622px" handleNextClick={this.handleNextClick5} handleBackClick={this.handleBackClick5} state={this.state} totalSlides={slideshow5Data.length} />
              </div>
            </div>
            <div className="back">
              <div className="page page5">
                <img src={globalState.cdnUrl + data.pageLeft} className="page-left" alt="" />
                <img src={globalState.cdnUrl + data.text7} className="book-text" style={{ left: "30px", top: "24px" }} alt="It was maybe the last truly iconic era. The last time when we more or less went through the motions of change together, everyone excited by the same things, at the same time." />
                <Icon ref="icon3" sprites={1} srcFront={globalState.cdnUrl + data.icon3Front} srcBack={globalState.cdnUrl + data.icon3Back} iconFlipSound={globalState.cdnUrl + data.iconFlipSound} globalState={globalState} style={{ position:'absolute', left: '355px', top: '165px', width: '150px', height: '150px' }} />
                <Icon ref="icon2" sprites={3} spriteW={148} spriteH={151} spriteTime={7} srcFront={globalState.cdnUrl + data.icon2Front} srcBack={globalState.cdnUrl + data.icon2Back} iconFlipSound={globalState.cdnUrl + data.iconFlipSound} globalState={globalState} style={{ position:'absolute', left: '195px', top: '155px', width: '148px', height: '151px' }} />
                <Icon ref="icon1" sprites={4} spriteTime={12} spriteW={144} spriteH={144} srcFront={globalState.cdnUrl + data.icon1Front} srcBack={globalState.cdnUrl + data.icon1Back} iconFlipSound={globalState.cdnUrl + data.iconFlipSound} globalState={globalState} style={{ position:'absolute', left: '40px', top: '162px', width: '144px', height: '144px' }} />

                <Icon ref="icon6" sprites={4} spriteW={147} spriteH={147} spriteTime={14} srcFront={globalState.cdnUrl + data.icon6Front} srcBack={globalState.cdnUrl + data.icon6Back} iconFlipSound={globalState.cdnUrl + data.iconFlipSound} globalState={globalState} style={{ position:'absolute', left: '358px', top: '323px', width: '147px', height: '147px' }} />
                <Icon ref="icon5" sprites={1} srcFront={globalState.cdnUrl + data.icon5Front} srcBack={globalState.cdnUrl + data.icon5Back} iconFlipSound={globalState.cdnUrl + data.iconFlipSound} globalState={globalState} style={{ position:'absolute', left: '205px', top: '322px', width: '153px', height: '152px' }} />
                <Icon ref="icon4" sprites={3} spriteW={150} spriteH={150} spriteTime={7} srcFront={globalState.cdnUrl + data.icon4Front} srcBack={globalState.cdnUrl + data.icon4Back} iconFlipSound={globalState.cdnUrl + data.iconFlipSound} globalState={globalState} style={{ position:'absolute', left: '50px', top: '318px', width: '150px', height: '150px' }} />
            

                <img src={globalState.cdnUrl + data.text8} className="book-text" style={{ left: "32px", top: "492px" }} alt="I always found this comforting. We were all in the same boat – or at least swimming nearby. Now, we’re all in our own little boats, in our own little oceans. It seems so much harder to be collectively surprised, exhilarated." />
              </div>
            </div>
          </div>
          <div className="flipper flipper2" ref="flipper2Ref">
            <div className="front">
              <div className="page page2">
                <img src={globalState.cdnUrl + data.pageRight} className="page-right" alt="" />
                <img src={globalState.cdnUrl + data.text2} className="book-text" style={{ left: "89px", top: "30px" }} alt="This isn’t Facebook: the photos have scratches, wrinkles and dust – people awkwardly framed with half-closed eyes. Just pictures taken with the hope they might preserve a moment." /> {/* eslint-disable-line */}
                <img src={globalState.cdnUrl + data.text3} className="book-text" style={{ left: "58px", top: "455px" }} alt="They reminded me of my own family album. My Dad died in 1999. When I try to picture him, I don’t see him – I see photos of him. My family albums have become a catalogue of evidence from my past. Though I had only stayed a short while in the actual town of Pine Point, I spent hours going through its memorial." />{/* eslint-disable-line */}
                {/* Pg 2 Carousel */}
                <Carousel ref="carousel1Ref" data={slideshow1Data} globalState={globalState} fullscreen={false} position={['88px', '140px']} size={["397px", "294px"]} state={this.state} />
                <SlideshowControllerSmall xPos="400px" yPos="405px" handleNextClick={this.handleNextClick1} handleBackClick={this.handleBackClick1} state={this.state} totalSlides={slideshow1Data.length} />
              </div>
            </div>
            <div className="back">
              <div className="page page3">
                <img src={globalState.cdnUrl + data.pageLeft} className="page-left" alt="" />
                <img src={globalState.cdnUrl + data.text4} className="book-text" style={{ left: "334px", top: "95px" }} alt="The site was the least disingenuous thing I had seen in a long time. These folks seemed to be holding a decades-long party." />
                <img src={globalState.cdnUrl + data.text5} className="book-text" style={{ left: "37px", top: "378px" }} alt="Looking in, it’s hard not to think that it was a great time to be alive and up North, in a time before seatbelts and sunscreen, when you could still pull block-long wheelies without fear of judgment or consequence." />
                {/* Pg 3 Carousel - Polaroid */}
                <Carousel ref="carousel2Ref" data={slideshow2Data} globalState={globalState} fullscreen={false} position={['85px', '48px']} size={["218px", "223px"]} state={this.state} />
                <img src={globalState.cdnUrl + data.polaroidFrame} className="book-text" style={{ left: "69px", top: "32px" }} alt="" />
                <SlideshowControllerSmall xPos="145px" yPos="311px" handleNextClick={this.handleNextClick2} handleBackClick={this.handleBackClick2} state={this.state} totalSlides={slideshow2Data.length} />
                {/* Pg 3 Carousel - Wheelie */}
                <Carousel ref="carousel3Ref" data={slideshow3Data} globalState={globalState} fullscreen={false} position={['254px', '428px']} size={["255px", "208px"]} state={this.state} />
                <SlideshowControllerSmall xPos="345px" yPos="627px" handleNextClick={this.handleNextClick3} handleBackClick={this.handleBackClick3} state={this.state} totalSlides={slideshow3Data.length} />
              </div>
            </div>
          </div>
          <div className="flipper flipper1" ref="flipper1Ref">
            <div className="front">
              <div className="cover">
                <img src={globalState.cdnUrl + data.bookCover} ref="bookCoverRef" alt="" />
              </div>
            </div>
            <div className="back">
              {/* page 1 */}
              <div className="page page1">
                <img src={globalState.cdnUrl + data.pageLeft} className="page-left" alt="" />
                <img src={globalState.cdnUrl + data.text0} className="book-text" style={{ left: "76px", top: "37px" }} alt="This is what it looks like:" />
                <img src={globalState.cdnUrl + data.text1} className="book-text" style={{ left: "50px", top: "506px" }} alt="Plenty of images of people in enormous trucks, in enormous holes in the ground, folks with hard hats standing in nondescript corrugated buildings or alongside vaguely threatening, finger-pinching machines. It was a mining town." />

                {/* Trucks */}
                <div className="trucks-wrapper" ref="trucksWrapperRef" role="button" tabIndex={0} onMouseMove={this.startTruckScroll} onMouseOut={this.stopTruckScroll} onBlur={this.stopTruckScroll}>
                  <div className="trucks" ref="trucksRef">
                    <div className="t1" ref="t1Ref">
                      <img src={globalState.cdnUrl + data.trucks1} alt="" />
                      <img src={globalState.cdnUrl + data.trucks2} alt="" />
                      <img src={globalState.cdnUrl + data.trucks3} alt="" />
                      <img src={globalState.cdnUrl + data.trucks4} alt="" />
                      <img src={globalState.cdnUrl + data.trucks5} alt="" />
                      <img src={globalState.cdnUrl + data.trucks6} alt="" />
                      <img src={globalState.cdnUrl + data.trucks7} alt="" />
                    </div>
                    <div className="t2" ref="t2Ref">
                      <img src={globalState.cdnUrl + data.trucks1} alt="" />
                      <img src={globalState.cdnUrl + data.trucks2} alt="" />
                      <img src={globalState.cdnUrl + data.trucks3} alt="" />
                      <img src={globalState.cdnUrl + data.trucks4} alt="" />
                      <img src={globalState.cdnUrl + data.trucks5} alt="" />
                      <img src={globalState.cdnUrl + data.trucks6} alt="" />
                      <img src={globalState.cdnUrl + data.trucks7} alt="" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        </>
        )}
      </>
    );
  }


};

export default IntroPage3
