import React from "react"
import { frameAnimation } from "../../helpers"
import Sound from 'react-sound';
import { gsap, Sine, Bounce } from "gsap/all";

let iconFlipSound;

class Icon extends React.Component {

    state = {
        playIconFlipSound: false,
        playStatus: Sound.status.PLAYING,
        iconFlipped: false
    }

    
    componentDidMount = () => {
        
        iconFlipSound = new Audio(this.props.iconFlipSound);
        
        gsap.set(this.refs.iconWrapperRef, {perspective:800});
        gsap.set(this.refs.iconRef, {transformStyle:"preserve-3d"});

        if(this.props.sprites > 1){
            frameAnimation(this.refs.imgFrontRef, this.props.spriteW, this.props.spriteH, this.props.sprites, 1, this.props.sprites, this.props.spriteTime, -1);
        }
    }

    onFocus = () => {
        //gsap.to(this.refs.iconRef, 0.2, {scaleX:1.05, scaleY:1.05, rotation:3, ease:Sine.easeOut});
    }
    onBlur = () => {
        //gsap.to(this.refs.iconRef, 0.1, {scaleX:1, scaleY:1, rotation:0, ease:Sine.easeOut});
    }
    onMouseOver = () => {
        gsap.to(this.refs.iconRef, 0.2, {scaleX:1.05, scaleY:1.05, rotation:3, ease:Sine.easeOut});
    }
    onMouseOut = () => {
        gsap.to(this.refs.iconRef, 0.2, {scaleX:1, scaleY:1, rotation:0, ease:Sine.easeOut});
        
    }

    bounce = () => {
        console.log("bounce")
        let icon = this.refs.iconRef;
        //icon.z = -50;
        gsap.set(icon, { z:-50 } );
        gsap.to(icon, 0.5 + Math.random(), { z:0, ease:Bounce.easeOut } );
    }

    onClick = (e) => {
        console.log("click icon");
        let target = e.currentTarget;
        let tr;
        //let tr ? target.rotationY == 180 ? 0 : 180;
        let halfTime = 0.2;

        this.state.iconFlipped ? tr = 0 : tr = 180;
        let img = this.refs.imgFrontRef;
        let txt = this.refs.imgBackRef;
        let completeParams = [img, txt];
        //var comleteParams = target.rotationY == 180 ? [img, txt] : [txt, img];
        if(this.state.iconFlipped){
            completeParams = [txt, img];
        }
        
        //console.log(target);
        //console.log("tr: " + tr);
        //console.log(Math.random() * 3)

        gsap.to(target, halfTime, { z:100, rotationY:90, ease:Sine.easeInOut, onComplete:this.changeVisibility, onCompleteParams:completeParams } );

        gsap.to(target, halfTime, { z:0, rotationY:tr, rotationZ:Math.random() * 3, delay:halfTime, ease:Sine.easeInOut, onComplete:this.updateState } );

        iconFlipSound.currentTime = 0;
        if(!this.props.globalState.baseAudioRef.isMuted()){
            iconFlipSound.play();
        }
        
    }

    changeVisibility = (bm1, bm2) => {
        //console.log(bm1);
        //console.log(bm2);
        bm1.style.visibility = "hidden";
        bm2.style.visibility = "visible";
    }

    updateState = () => {
        this.setState({iconFlipped: !this.state.iconFlipped});
    }
    

    render(){
        return (
            <>
            
            <div style={this.props.style} ref="iconWrapperRef">
                <button className="iconContainer"  ref="iconRef" onClick={this.onClick} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onFocus={this.onFocus} onBlur={this.onBlur}>
                    <div ref="imgFrontRef" type="image" className="icon icon--front" style={{ backgroundImage: `url(${this.props.srcFront})`}} alt=""></div>
                    <img ref="imgBackRef" type="image" className="icon icon--back" src={this.props.srcBack} alt="" />
                </button>
            </div>
            </>
        );
    }
}

export default Icon
